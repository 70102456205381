import React from 'react';
import './Contactus.css';
import location from '../../assets/images/location.png';
import envelop from '../../assets/images/envelop.png';
import mobilebutton from '../../assets/images/mobilebutton.png';
function Contactus() {
	return (
		<div className="content-wrapper">
			<div className="heading">
				<h2 className="m-auto">Contact Us</h2>
			</div>
			<div className="contactuswrapper">
				<div className="containers">
					<div className="heading-contact text-center">
						<label className="m-auto">
							We’d Love To <strong>Help You</strong>
						</label>
					</div>
					<div className="consumer-top  consumer-middle flex justify-center gap-100">
						<div className="contactus">
							<iframe
							src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d14000.347726944296!2d77.14676663775644!3d28.687046052415283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sA-4%2F76%2C%20BLOCK-A%2C%20LAWARANCE%20ROAD%2C%20KESHAV%20PURAM%2C%20NEW%20DELHI%2CNORTH%20DELHI%20DELHI%2C%20110035!5e0!3m2!1sen!2sin!4v1697701270410!5m2!1sen!2sin"
								width="100%"
								height="100%"
								allowfullscreen=""
								loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"
								title='map'
							></iframe>
							<div className="contactusdetail">
								<div className="contactaddress">
									<div className="address">
										<label>Registered Address</label>
									</div>
									<div className="address">
										<img src={location} alt='location' />
										<p>
										GROUND FLOOR, F-26/14, SECTOR-7, <br />NEW DELHI,NORTH WEST DELHI, DELHI, 110085
										</p>
									</div>
								</div>
								<div className="contactaddress">
									<div className="address">
										<label>Contact Us</label>
									</div>
									<div className="address">
										<img src={envelop} alt='envelop' />
										<p>
											<strong>Email Id :</strong>office.balajitraders@gmail.com
										</p>
									</div>
									<div className="address">
										<img src={mobilebutton} alt='mobilebutton' />
										<p>
											<strong>Phone No :</strong>7247870103
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Contactus;
