import { useState, useEffect, useRef } from 'react';
import mobile from '../../assets/images/Prepaid.svg';
import { FilterWrapper, TableWrapper } from '../UI/styledConstants';
import { getTransactionHistoryRequest } from '../../utils/api';
import Pagination from '../Pagination/Pagination';
import { isEmpty, pdfData } from '../../utils/common';
import LocalLoader from '../UI/Loader/LocalLoader';
import { CSVLink } from 'react-csv';

const TransactionHistory = () => {
	const [transactionHistory, setTransactionHistory] = useState([]);
	const [downloadLoader, setDownloadLoader] = useState('');
	const [filter, setFilter] = useState({
		pageNo: 0,
		pageSize: 10,
		fromDate: '',
		toDate: '',
		transactionType: 'MOBILE',
		txnId: '',
	});

	const [paging_data, setPagingData] = useState(null);
	const [loading, setLoading] = useState(null);
	const [totalElement, setTotalElement] = useState('');
	const [csvData, setCsvData] = useState([]);

	const getTransactionHistory = (key) => {
		let param = {
			fromDate: filter.fromDate,
			toDate: filter.toDate,
			serviceType: filter.transactionType,
			txnId: filter.txnId,
			pagination: {
				pageNo: filter.pageNo,
				pageSize: filter.pageSize,
			},
		};

		if (key === 'csv' || key === 'pdf') {
			if (key === 'csv') {
				setDownloadLoader('csv');
			} else {
				setDownloadLoader('pdf');
			}
			param.pagination.pageSize = totalElement;
		} else {
			param.pagination.pageSize = 10;
			setLoading(true);
		}

		getTransactionHistoryRequest(param).then((resp) => {
			setLoading(false);
			setDownloadLoader('');
			if (resp?.success) {
				if (key !== 'csv' && key !== 'pdf') {
					setTransactionHistory(resp?.data?.content);
					setTotalElement(resp?.data?.totalElements);
					setPagingData({
						number: resp?.data?.number,
						totalPages: resp?.data?.totalPages,
					});
				} else if (key === 'csv') {
					setCsvData(resp?.data?.content);
					const csvlink = link.current;
					csvlink.link.click();
				} else if (key === 'pdf') {
					setCsvData(resp?.data?.content);
					pdfDownload(resp?.data?.content);
				}
			} else {
				setTransactionHistory([]);
			}
		});
	};

	const filterChangeHandler = (e) => {
		const { name, value } = e.target;
		if (name === 'transactionType') {
			setFilter({
				pageNo: 0,
				pageSize: 10,
				fromDate: '',
				toDate: '',
				transactionType: value,
				txnId: '',
			});
		} else {
			setFilter({ ...filter, [name]: value, pageNo: 0 });
		}
	};

	useEffect(() => {
		getTransactionHistory('');
	}, [filter.transactionType, filter.pageNo, filter.sortBy]);

	const showUsername = filter.transactionType === 'MOBILE' || filter.transactionType === 'DTH';

	const options = [
		{ value: 'MOBILE', label: 'Mobile' },
		{ value: 'AEPS', label: 'AEPS' },
		{ value: 'DTH', label: 'DTH' },
		{ value: 'SEND_MONEY', label: 'DMT' },
		{ value: 'ELECTRICITY', label: 'Electricity' },
		{ value: 'WATER', label: 'Water' },
		{ value: 'CREDIT_CARD', label: 'Credit Card' },
	];

	const link = useRef(null);

	const pdfDownload = (data) => {
		const tableHeader = [];

		if (!showUsername) {
			tableHeader.push({
				accountNumber: 'Merchant Name',
				transactionId: 'Transaction Id',
				transactionDate: 'Date',
				status: 'Status',
				amount: 'Amount',
			});
		} else {
			tableHeader.push({
				rechargedNumber: 'Merchant Name',
				transactionId: 'Transaction Id',
				transactionDate: 'Date',
				status: 'Status',
				amount: 'Amount',
			});
		}

		pdfData(data, tableHeader, 'Transaction History');
	};

	return (
		<div>
			<h2 className="user-first">
				<span className="user-welcome">Transaction History </span>
			</h2>
			<div className="user-wrapper flex">
				<div className="personal-information transaction">
					<FilterWrapper className="flex items-center justify-between">
						<div className="filterBox">
							<div className="filter-text">Filter By</div>

							<div className="input-field">
								<select placeholder="Select transactionType" value={filter.transactionType} defaultValue={'MOBILE'} name="transactionType" onChange={filterChangeHandler}>
									{options.map((val, index) => {
										return (
											<option key={index} value={val.value}>
												{val.label}
											</option>
										);
									})}
								</select>
							</div>
							<div className="input-field">
								<input type="text" name="txnId" placeholder="Transaction Id" value={filter.txnId} onChange={filterChangeHandler} />
							</div>

							<div className="input-field date-filter">
								{/* <img className='img-1' src={calanderIcon} alt='calanderIcon' />
							     <select>
									<option>Main Wallet</option>
									<option >AEPS Wallet</option>
								 </select>
								 <img className='img-2' src={calanderIcon} alt='calanderIcon' /> */}
								<input type="date" placeholder="From" name="fromDate" value={filter.fromDate} onChange={filterChangeHandler} />
								{/* <img className='img-3' src={calanderIcon} alt='calanderIcon' /> */}
								<input type="date" placeholder="To" name="toDate" value={filter.toDate} min={filter.fromDate} onChange={filterChangeHandler} disabled={isEmpty(filter.fromDate)} />
							</div>
							<div className="input-field">
								<button className="filterButton" onClick={() => getTransactionHistory('')}>
									GO
								</button>
							</div>
							<div className="input-field csv">
								<CSVLink ref={link} data={csvData} filename={'Transaction-History.csv'}></CSVLink>
								{downloadLoader !== 'csv' ? (
									<button className="filterButton" onClick={() => getTransactionHistory('csv')}>
										CSV
									</button>
								) : (
									<button className="filterButton btn-loader" onClick={() => getTransactionHistory('pdf')}>
										<div className="btn-loader">
											<i className="fa-solid fa-spinner"></i>
										</div>
									</button>
								)}
								{downloadLoader !== 'pdf' ? (
									<button className="filterButton" onClick={() => getTransactionHistory('pdf')}>
										PDF
									</button>
								) : (
									<button className="filterButton btn-loader" onClick={() => getTransactionHistory('pdf')}>
										<div className="btn-loader">
											<i className="fa-solid fa-spinner"></i>
										</div>
									</button>
								)}
							</div>
						</div>
					</FilterWrapper>

					<TableWrapper className="mt20">
						<table className="table">
							<thead className="table-head">
								<tr>
									<th>Sr No</th>
									<th>Merchant Name</th>
									<th>Transaction Id</th>
									<th>Date</th>
									<th>Status</th>
									<th className="text-right">Amount</th>
								</tr>
							</thead>
							<tbody className="table-body">
								{transactionHistory &&
									transactionHistory.map((data, i) => {
										const update = new Date(data.transactionDate).toLocaleDateString('en-us', { day: 'numeric', month: 'short', year: 'numeric' });
										return (
											<tr key={i}>
												<td>
													<td>{filter.pageSize * filter.pageNo + (i + 1)}</td>
												</td>
												{showUsername ? (
													<td>
														<div className="image-with-text">
															<img src={`${data?.serviceType ? mobile : null}`} />
															<span className="text">{data?.rechargedNumber || 'null'}</span>
														</div>
													</td>
												) : (
													<td>
														<div className="image-with-text">
															<img src={`${data?.serviceType ? mobile : null}`} />
															<span className="text">{data?.accountNumber || 'null'}</span>
														</div>
													</td>
												)}
												<td>{data?.transactionId || 'null'}</td>
												<td>{update}</td>
												<td className={`${data?.status?.toLowerCase() === 'success' || data?.status?.toLowerCase() === 'done' ? 'success' : 'fail'}`}>
													{(data?.status).toUpperCase() || 'null'}
												</td>
												<td className="text-right">₹ {data?.amount?.toFixed(2) || 'null'}</td>
											</tr>
										);
									})}

								{isEmpty(transactionHistory) && !loading && (
									<tr>
										<td></td>
										<td></td>
										<td>NO record found</td>
										<td></td>
										<td></td>
									</tr>
								)}

								{isEmpty(transactionHistory) && loading && (
									<tr>
										<td></td>
										<td></td>
										<td>
											<LocalLoader />
										</td>
										<td></td>
										<td></td>
									</tr>
								)}
							</tbody>
						</table>
					</TableWrapper>
					{paging_data && paging_data?.totalPages > 1 && (
						<Pagination currentPage={paging_data?.number} totalPages={paging_data?.totalPages} getCurrentPageData={(page) => setFilter({ ...filter, pageNo: page })} />
					)}
				</div>
			</div>
		</div>
	);
};
export default TransactionHistory;
