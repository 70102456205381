import React from 'react';
import './GrowYourBussion.css';
import user from '../../assets/images/user-dil.svg';
import bag from '../../assets/images/badge-check.svg';
import shield from '../../assets/images/shield-check.svg';
import smile from '../../assets/images/smile.svg';

const GrowYourBussion = () => {
	return (
		<div className="GrowYourBussionWrapper">
			<div className="container">
				<div className="GrowYourBussion-wrapper ">
					<div className="top-wrapper flex gap-30 justify-center">
						<div className="left">
							<img src={'https://pay2mobile.fra1.digitaloceanspaces.com/mobile-1.png'} alt="mobile" />
						</div>
						<div className="right">
							<h2 className="heading-2">
								Grow Your Business With <span className="strong">Balaji Traders!</span>
							</h2>
							<p className="h3">Secure and reliable Services Provided by Balaji Traders is India's best leading online service provider.</p>

							<p>
								We at Balaji Traders deliver Aadhaar Enabled Payment systems ( AEPS), Domestic Money Transfer, Recharge( Mobile, Dth, Data Card), and Bill Payment System( Electricity,
								Landline, Mobile Bill Payment) of all leading service providers at the unbelievably lowest price. Now you don’t have to stand in queue for all Recharge, Bill Payment,
								AEPS and Money Transfer, You Can pay easily all these payments with Balaji Traders store services in India. You can transfer money from any place to any bank account at any
								time in India using Balaji Traders Store domestic money transfer service.
							</p>
						</div>
					</div>

					<div className="middle-wrapper">
						<h2 className="heading-2 text-center">
							Get To Know <span className="strong">Balaji Traders!</span>
						</h2>
						<div className="center">
							<div className="box">
								<div className="img center">
									<img src={bag} alt="shield" />
								</div>
								<h2>100%</h2>
								<p>Success Transaction</p>
							</div>
							<div className="box">
								<div className="img center">
									<img src={user} alt="user" />
								</div>
								<h2>200+</h2>
								<p>Total Retailers</p>
							</div>
							<div className="box">
								<div className="img center">
									<img src={shield} alt="shield" />
								</div>
								<h2>100%</h2>
								<p>Secure Payment</p>
							</div>
							<div className="box box-4">
								<div className="img center">
									<img src={smile} alt="smile" />
								</div>
								<h2>1.5 Lac</h2>
								<p>Happy Customers</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GrowYourBussion;
