export const tabs = [
	// {
	// 	title: 'Recharge',
	// 	icon: 'IconMobileRecharge',
	// 	id: 'tab1',
	// 	show: 'all',
	// 	apiPermission: 'Recharge',
	// },

	// {
	// 	title: 'Credit Card',
	// 	icon: 'IconCreditCard',
	// 	id: 'tab3',
	// 	show: 'all',
	// 	apiPermission: 'CreditCard',
	// },
	
	// {
	// 	title: 'AEPS',
	// 	icon: 'IconAEPS',
	// 	id: 'tab6',
	// 	show: 'login',
	// 	apiPermission: 'AEPS',
	// },
	// {
	// 	title: 'Send Money',
	// 	icon: 'IconSendmoney',
	// 	id: 'tab7',
	// 	//show: 'login',
	// 	show: 'all',
	// 	apiPermission: 'Send_Money',
	// },

	// {
	// 	title: 'DTH',
	// 	icon: 'IconDTH',
	// 	id: 'tab5',
	// 	show: 'all',
	// 	apiPermission: 'DTH',
	// },
	{
		title: 'Electricity',
		icon: 'IconBillRecharge',
		id: 'tab2',
		show: 'all',
		apiPermission: 'Electricity',
	},
	

	// {
	// 	title: 'Add Money',
	// 	icon: 'IconAddmoney',
	// 	id: 'tab8',
	// 	show: 'all',
	// 	apiPermission: 'Add_Money',
	// },
	
	{
		title: 'Broadband',
		icon: 'IconBroadband',
		id: 'tab4',
		show: 'all',
		apiPermission: 'Broadband',
	},
	{
		title: 'Water',
		icon: 'IconWater',
		id: 'tab10',
		show: 'all',
		apiPermission: 'Water',
	},
	
	
];
