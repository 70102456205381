import React from 'react';

const IconGas = ({ color = '#fff',color_2="#a9a1a1" }) => {
  return (
   

<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 22 22">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_678" data-name="Rectangle 678" width="22" height="22" transform="translate(137 327)" fill="#fff"/>
    </clipPath>
  </defs>
  <g id="pipedGas" transform="translate(-137 -327)" clip-path="url(#clip-path)">
    <g id="Group_356" data-name="Group 356" transform="translate(137.846 328.828)">
      <g id="Group_288" data-name="Group 288" transform="translate(0 7.149)">
        <g id="Rectangle_566" data-name="Rectangle 566" transform="translate(0 7.1)" fill="#fff" stroke="#00286f" strokeLinecap="round" strokeWidth="1">
          <rect width="20.308" height="5.077" rx="2.538" stroke="none"/>
          <rect x="0.5" y="0.5" width="19.308" height="4.077" rx="2.038" fill="none"/>
        </g>
        <g id="Rectangle_567" data-name="Rectangle 567" transform="translate(8.462 2.023)" fill="#fff" stroke="#00286f" strokeLinecap="round" strokeWidth="1">
          <rect width="2.538" height="5.923" stroke="none"/>
          <rect x="0.5" y="0.5" width="1.538" height="4.923" fill="none"/>
        </g>
        <g id="Group_287" data-name="Group 287" transform="translate(3.385 5.408)">
          <g id="Rectangle_570" data-name="Rectangle 570" transform="translate(0 0)" fill="#fff" stroke="#00286f" strokeLinecap="round" strokeWidth="1">
            <rect width="3.385" height="7.615" rx="1.692" stroke="none"/>
            <rect x="0.5" y="0.5" width="2.385" height="6.615" rx="1.192" fill="none"/>
          </g>
          <g id="Rectangle_571" data-name="Rectangle 571" transform="translate(10.154 0)" fill="#fff" stroke="#00286f" strokeLinecap="round" strokeWidth="1">
            <rect width="3.385" height="7.615" rx="1.692" stroke="none"/>
            <rect x="0.5" y="0.5" width="2.385" height="6.615" rx="1.192" fill="none"/>
          </g>
        </g>
        <g id="Rectangle_568" data-name="Rectangle 568" transform="translate(14.087 0) rotate(90)" fill="#fff" stroke="#00286f" strokeLinecap="round" strokeWidth="1">
          <rect width="2.954" height="8.064" rx="1.477" stroke="none"/>
          <rect x="0.5" y="0.5" width="1.954" height="7.064" rx="0.977" fill="none"/>
        </g>
      </g>
      <path id="Path_552" data-name="Path 552" d="M7.638-1.513a6.113,6.113,0,0,0-.452,3.669c.33,2.3,1.217,2.66,1.417,2.67C8.82,4.972,10.446,1.6,10.446,3c2.991,4.373-.359,7.523-3.507,7.523s-4.8-2.552-4.8-5.7c.052-1.592,1.1.824,1.982,1.311a4.619,4.619,0,0,1-.174-4.086A9.3,9.3,0,0,1,7.638-1.513Z" transform="translate(3.203 1.513)" fill="#fff" stroke="#00baf2" strokeLinecap="round" strokeWidth="1"/>
    </g>
  </g>
</svg>

  );
}

export default IconGas;
