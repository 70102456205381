import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { getUserWalletTransactionRequest } from '../../utils/api';
import { FilterWrapper, TableWrapper } from '../UI/styledConstants';
import Pagination from '../Pagination/Pagination';
import { isEmpty, pdfData } from '../../utils/common';
import LocalLoader from '../UI/Loader/LocalLoader';
import { CSVLink } from 'react-csv';

const WalletHistory = () => {
	const [downloadLoader, setDownloadLoader] = useState('');
	const [filter, setFilter] = useState({
		fromDate: '',
		toDate: '',
		txnType: null,
		serviceType: '',
		pageNo: 0,
		pageSize: 10,
		walletType: 'MAIN_WALLET',
	});

	const [loading, setLoading] = useState(null);

	const [walletHistory, setWalletHistory] = useState([]);
	const [paging_data, setPagingData] = useState(null);
	const [totalElement, setTotalElement] = useState('');
	const [csvData, setCsvData] = useState([]);

	const link = useRef(null);

	const getWalletHistory = async (key) => {
		let txnType = filter.txnType;
		let fromDate = '';
		let toDate = '';
		if (filter.txnType === '') {
			txnType = null;
		}

		if (!isEmpty(filter.fromDate)) {
			const parts = filter.fromDate.split('-');
			const formattedDate = parts[0] + '-' + parts[1] + '-' + parts[2];
			fromDate = formattedDate;
		}
		if (!isEmpty(filter.toDate)) {
			const parts = filter.toDate.split('-');
			const formattedDate = parts[0] + '-' + parts[1] + '-' + parts[2];
			toDate = formattedDate;
		}

		const param = {
			walletType: filter.walletType,
			fromDate: fromDate,
			toDate: toDate,
			txnType: txnType,
			serviceType: '',
			sortBy: '',
			pagination: {
				pageNo: filter.pageNo,
				pageSize: 10,
			},
		};
		if (key === 'csv' || key === 'pdf') {
			if (key === 'csv') {
				setDownloadLoader('csv');
			} else {
				setDownloadLoader('pdf');
			}
			param.pagination.pageSize = totalElement;
		} else {
			setLoading(true);
		}
		setLoading(true);
		getUserWalletTransactionRequest(param).then((resp) => {
			setLoading(false);
			setDownloadLoader('');
			if (resp?.success) {
				if (key !== 'csv' && key !== 'pdf') {
					setWalletHistory(resp?.data?.content);
					setTotalElement(resp?.data?.totalElements);
					setPagingData({
						number: resp?.data?.number,
						totalPages: resp?.data?.totalPages,
					});
				} else if (key === 'csv') {
					setCsvData(resp?.data?.content);

					const csvlink = link.current;
					csvlink.link.click();
				} else if (key === 'pdf') {
					setCsvData(resp?.data?.content);
					pdfDownload(resp?.data?.content);
				}
			} else {
				setWalletHistory([]);
			}
		});
	};

	const filterChangeHandler = (e) => {
		const { name, value } = e.target;
		if (name === 'toDate') {
			if (isEmpty(filter.fromDate)) {
				return false;
			}
		}
		setFilter({ ...filter, pageNo: 0, [name]: value });
	};

	useEffect(() => {
		getWalletHistory('');
	}, [filter.walletType, filter.pageNo, filter.txnType]);

	const pdfDownload = (data) => {
		const tableHeader = [
			{
				userId: 'User Id',
				Remark: 'Remark',
				transactionId: 'Transaction Id',
				createdDate: 'Date',
				openingAmount: 'Opening Amount',
				currentAmount: 'Closing Amount',
				charges: 'Service Charge',
				commission: 'Commission',
				// gst: "GST",
				transactionType: 'Transaction Type',
				txnStatus: 'Status',
				transactionAmout: 'Amount',
			},
		];

		pdfData(data, tableHeader, 'Transaction History');
	};

	return (
		<div>
			<h2 className="user-first">
				<span className="user-welcome">Wallet History </span>
			</h2>
			<div className="user-wrapper flex">
				<div className="personal-information  transaction">
					<FilterWrapper className="flex items-center justify-between">
						<div className="filterBox">
							<div className="filter-text">Filter By</div>

							<div className="input-field">
								<select placeholder="Select WalletType" value={filter?.walletType} name="walletType" onChange={filterChangeHandler}>
									<option selected value={'MAIN_WALLET'}>
										Main Wallet
									</option>
									<option value={'AEPS_WALLET'}>AEPS Wallet</option>
								</select>
							</div>
							<div className="input-field">
								<select placeholder="Transaction Type" value={filter?.txnType} name="txnType" onChange={filterChangeHandler}>
									<option selected value={''}>
										Transaction Type
									</option>
									<option value={'CREDIT'}>CREDIT</option>
									<option value={'DEBIT'}>DEBIT</option>
								</select>
							</div>
							{/* <div className="input-field">
								<input type="text" placeholder="Mobile" />
							</div> */}
							<div className="input-field date-filter">
							<span style={{fontWeight:"600", marginLeft:"8px"}}>From</span>
								<input type="date" placeholder="DD-MM-YYYY" name="fromDate" value={filter.fromDate} onChange={filterChangeHandler} />
								<span style={{fontWeight:"600"}}>To</span>
								<input type="date" placeholder="DD-MM-YYYY" name="toDate" value={filter.toDate} min={filter.fromDate} onChange={filterChangeHandler} disabled={isEmpty(filter.fromDate)} />
							</div>
							<div className="input-field">
								<button className="filterButton" onClick={() => getWalletHistory('')}>
									GO
								</button>
							</div>
							<div className="input-field csv">
								{downloadLoader !== 'csv' ? (
									<button className="filterButton csv" onClick={() => getWalletHistory('csv')}>
										CSV
									</button>
								) : (
									<button className="filterButton btn-loader">
										<div className="btn-loader">
											<i className="fa-solid fa-spinner"></i>
										</div>
									</button>
								)}
								{downloadLoader !== 'pdf' ? (
									<button className="filterButton" onClick={() => getWalletHistory('pdf')}>
										PDF
									</button>
								) : (
									<button className="filterButton ">
										<div className="btn-loader">
											<i className="fa-solid fa-spinner"></i>
										</div>
									</button>
								)}
								<CSVLink ref={link} data={csvData} filename={'Transaction-History.csv'}></CSVLink>
							</div>
						</div>
					</FilterWrapper>
					<TableWrapper className="mt20">
						<table className="table">
							<thead className="table-head">
								<tr>
									<th>Sr. No</th>
									<th>User Id</th>
									<th className="text-right remark">Remark</th>
									<th>Transaction Id</th>
									<th>Date</th>
									<th>Opening Amount</th>
									<th className="text-right">Closing Amount</th>
									<th>Service Charge</th>
									<th>Commission</th>
									<th>GST</th>
									<th>Transaction Type</th>
									<th>Status</th>
									<th className="text-right">Transaction Amount</th>
								</tr>
							</thead>
							<tbody className="table-body">
								{walletHistory &&
									walletHistory.map((data, i) => {
										const update = new Date(data.createdDate).toLocaleDateString('en-us', { day: 'numeric', month: 'short', year: 'numeric' });
										return (
											<tr key={i}>
												<td>{filter.pageSize * filter.pageNo + (i + 1)}</td>
												<td>{data.userId || 'null'}</td>
												<td className=" remark">{data.remarks || 'null'}</td>
												<td style={{ whiteSpace: 'normal' }}>{data.transactionId || 'null'}</td>
												<td>{update}</td>
												<td>₹ {data?.openingAmount?.toFixed(2) || 0}</td>
												<td>₹ {data.currentAmount?.toFixed(2) || 0}</td>
												<td>₹ {data?.charges?.toFixed(2) || 0}</td>
												<td>₹ {data?.commission?.toFixed(2) || 0}</td>
												<td>₹ {data?.gst?.toFixed(2) || 0}</td>
												<td> {data.transactionType}</td>
												<td style={(data?.txnStatus).toUpperCase() === 'SUCCESS' ? { color: 'green' } : { color: 'red' }}> {data?.txnStatus || null}</td>
												<td className={`text-right ${data.transactionType == 'CREDIT' ? '' : ''}`}>₹ {data.transactionAmout?.toFixed(2) || 0}</td>
											</tr>
										);
									})}

								{isEmpty(walletHistory) && !loading && (
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td>NO record found</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								)}

								{isEmpty(walletHistory) && loading && (
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td>
											<LocalLoader />
										</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								)}
							</tbody>
						</table>
					</TableWrapper>
					{paging_data && paging_data?.totalPages > 1 && (
						<Pagination currentPage={paging_data?.number} totalPages={paging_data?.totalPages} getCurrentPageData={(page) => setFilter({ ...filter, pageNo: page })} />
					)}
				</div>
			</div>
		</div>
	);
};
export default WalletHistory;
