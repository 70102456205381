/* eslint-disable import/no-anonymous-default-export */

const financeService ="/finance-service"
const  auth = "/auth"
export default {
	//API_BASEPATH: 'https://www.pay2mobiles.com',
	API_BASEPATH: '',
	login: {
		API_AUTHENTICATE: auth+'/api/authenticate',
		API_PERMISSION: auth+'/api/frontend/permissions',
		RESET_PASSWORD: auth+'/api/user/account/reset-password/init',
		SEND_OTP: auth+'/api/service/send-otp',
		VERIFY_OTP: auth+'/api/user/account/reset-password/finish',
		USER_PROFILE: financeService+'/api/user/account',
		// CHANGE_PASSWORD: '/api/user/account/change-password',
	},
	mobileRecharge: {
		RECHARGE: financeService+'/api/recharge/phoneNumber',
		PLANS: financeService+'/api/recharge/plan',
		ZONE: financeService+'/api/zone/list',
		OPERATOR: financeService+'/api/operator/MOBILE',
	},
	transactionHistory: {
		TRANSACTION_HISTORY: financeService+'/api/report/transactions',
	},
	dth: {
		DTH_RECHARGE: financeService+'/api/recharge/DTH',
		DTH_PLANS: financeService+'/api/recharge/DTH/plan',
		DTH_DETAILS: financeService+'/api/recharge/DTH/',
		OPERATOR: financeService+'/api/operator/DTH',
	},
	payBills: {
		GET_PROVIDER_LIST_URL: financeService+'/api/bill/provider/list',
		GET_PROVIDER_DETAILS_URL: financeService+'/api/bill/provider/details',
		FETCH_BILL_URL: financeService+'/api/bill/enquiry',
		PAY_BILL_URL: financeService+'/api/pg/initiate',
	},
	water: {
		OPERATOR: financeService+'/api/bill/WATER',
	},
	broadband: {
		OPERATOR: financeService+'/api/bill/BROADBAND',
	},
	wallet: {
		USER_WALLET: financeService+'/api/wallet',
		//USER_WALLET_TRANSACTIONS: financeService+'/api/wallet/transactions',
		USER_WALLET_TRANSACTIONS: financeService+'/api/wallet/filter/transactions',
	},
	sendMoney: {
		VERIFY_SENDER_STATUS_URL: financeService+'/api/sendMoney/senderStatus',
		REGISTER_SENDER_URL: financeService+'/api/sendMoney/registerSender',
		SEND_MONEY_URL: financeService+'/api/sendMoney',
		ADD_BENEFICIARY_URL: financeService+'/api/sendMoney/registerBeneficiary',
		VERIFY_SENDER_URL: financeService+'/api/sendMoney/verifySender',
		DELETE_BENEFICIARY_URL: financeService+'/api/sendMoney/deleteBeneficiary',
	},
	aeps: {
		AEPS_BANK_LIST: financeService+'/api/aeps/bank/list',
		CASH_WITHDRAWAL: financeService+'/api/aeps/cash-withdrawal',
		BALANCE_ENQUIRY: financeService+'/api/aeps/balance-enquiry',
		MINI_STATEMENT: financeService+'/api/aeps/mini-statement',
		CHECK_AEPS_STATUS:financeService+'/api/aeps/outlet/login/status',
		MERCHANT_LOGIN_FINGERPRINT:financeService+'/api/aeps/outlet/login'
	},
	creditCard: {
		CREDIT_CARD_BILL_PAY: financeService+'/api/bill/credit-card',
	},
};
